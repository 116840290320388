import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import NextLink from 'next/link';
import {useRouter} from 'next/router';
import {Button, Divider, Flex, SimpleGrid, Text, Link} from '@chakra-ui/react';
import {Card, CardContent, CardTitle} from 'dp-chakra/src/components/core/Card';
import {useDpErrorToast} from 'dp-chakra/src/hooks/use-dp-toast';
import {FormEmailInput} from 'dp-chakra/src/components/core/FormEmailInput/FormEmailInput';
import {useSearchParams} from 'next/navigation';
import {AccountCardHeader} from '../AccountCard/AccountCardHeader';
import {SignInWithAppleButton} from '../SignInWithAppleButton/SignInWithAppleButton';
import {SignInWithGoogleButton} from '../SignInWithGoogleButton/SignInWithGoogleButton';
import {SignInWithLineButton} from '../SignInWithLineButton/SignInWithLineButton';
import {SignInWithMedicalTribuneButton} from '../SignInWithMedicalTribuneButton/SignInWithMedicalTribuneButton';
import {useMutationSignInMethodsForEmail} from '../../../packages/firebase-core/src/hooks/use-mutation-sign-in-methods-for-email';
import {useHandleRedirection} from '../../../packages/account-server/hooks/use-handle-redirection';
import {toCallbackUrl} from '../../../utils/callback-handler';
import {convertToJpFirebaseError} from '../../../packages/firebase-core/src/services/convert-to-jp-firebase-error';

const ENABLE_MT_AUTH = 'dp:enable-mt-auth';

const authFieldIds = {
  email: 'email',
} as const;

type AuthFieldId = keyof typeof authFieldIds;

type AuthFiledValues = Record<AuthFieldId, string>;

export const AuthCard: React.FC = () => {
  const dpErrorToast = useDpErrorToast();
  const router = useRouter();
  const searchParameters = useSearchParams();

  const enableMtAuth = localStorage.getItem(ENABLE_MT_AUTH) === 'true';

  const methods = useForm<AuthFiledValues>();

  const {isLoading: isHandleRedirectionLoading, error: handleRedirectionError} =
    useHandleRedirection({
      onSignIn() {
        toCallbackUrl();
      },
    });

  const {
    mutate: fetchSignInMethodsForEmail,
    isPending: isFetchSignInMethodsForEmailLoading,
  } = useMutationSignInMethodsForEmail({
    onError(error) {
      dpErrorToast({
        title: error.message,
      });
    },
  });

  const handleSubmit = (data: AuthFiledValues) => {
    fetchSignInMethodsForEmail(
      {
        email: data.email,
      },
      {
        onSuccess(providers) {
          if (providers.length > 0) {
            void router.push({
              pathname: '/signin',
              query: {
                ...router.query,
                email: data.email,
              },
            });
          } else {
            void router.push({
              pathname: '/signup',
              query: {
                ...router.query,
                email: data.email,
              },
            });
          }
        },
      },
    );
  };

  useEffect(() => {
    if (handleRedirectionError) {
      const jpError = convertToJpFirebaseError(handleRedirectionError);
      dpErrorToast({
        title: jpError.message,
      });
    }
  }, [handleRedirectionError, dpErrorToast]);

  if (isHandleRedirectionLoading) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Card>
          <AccountCardHeader />
          <CardContent>
            <CardTitle>
              ログイン{' '}
              <Text as="span" fontWeight="normal">
                または
              </Text>{' '}
              新規登録
            </CardTitle>

            <SimpleGrid columns={1} spacing={4}>
              <SignInWithGoogleButton />

              <SignInWithAppleButton />

              <SignInWithLineButton />

              {enableMtAuth && <SignInWithMedicalTribuneButton />}
            </SimpleGrid>

            <Flex marginY={4} alignItems="center">
              <Divider />
              <Text marginX={3} color="fontColor.sub" whiteSpace="nowrap">
                または
              </Text>
              <Divider />
            </Flex>

            <SimpleGrid column={1} spacing={4}>
              <FormEmailInput
                id={authFieldIds.email}
                options={{
                  required: true,
                }}
              />

              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isFetchSignInMethodsForEmailLoading}
              >
                続ける
              </Button>

              {/* パスワード再設定 */}
              <Link
                as={NextLink}
                href={`/password-reset?${searchParameters.toString()}`}
                color="fontColor.sub"
                fontSize="md"
                textAlign="right"
              >
                パスワードをお忘れの方
              </Link>
            </SimpleGrid>
          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
};
